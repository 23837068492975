<template>
  <div class="flex flex-col min-h-screen">
    <SkipToMainContent />
    <div class="mb-30">
      <nav class="border-b border-b-gray-300 py-15 md:py-20 lg:py-25">
        <div class="container">
          <a href="/" class="block">
            <span class="sr-only">Zur Startseite</span>
            <Logo inline class="text-base lg:text-lg xl:text-2xl" />
          </a>
        </div>
      </nav>

      <div id="main" class="page" role="region" aria-live="polite">
        <slot />
      </div>
    </div>

    <footer class="bg-gray-200 py-20 lg:py-40 mt-auto">
      <div class="container">
        <div class="font-bold text-blue-900 text-sm">
          &copy; {{ copyright }}
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts" setup>
// Minimal layout for error pages.

// IMPORTANT: Do not use $texts or any other utils/composables here that
// require runtime data. This also applies for all components and nested
// components. The layout is used when rendering error pages or
// maintenance pages, for which there is no way to fetch runtime data,
// e.g. because Drupal is down.
const year = new Date().getFullYear().toString()
const copyright = `${year} Kanton Basel-Stadt`
</script>
